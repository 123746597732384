import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box, Typography } from '@mui/material';

const HomePage = () => {
  return (
    <div className="app-background">
      <Helmet>
        <title>Welcome to SporeLore!</title>
        <meta property="og:title" content="Welcome to SporeLore!" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="https://sporelore.co/static/images/preview.png" />
        <meta property="og:url" content="https://sporelore.co" />
        <meta property="og:site_name" content="SporeLore" />
      </Helmet>
      <Container maxWidth="xl">
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>Coming Soon</Typography>
          <Typography variant="body1" gutterBottom>Welcome to SporeLore! Our site is under construction. Stay tuned for more updates.</Typography>
          <Typography variant="body1" gutterBottom>
            <a href="/dose-calculator">Check out our Dosing Calculator</a>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <a href="/substrate-visualizer">Check out our Substrate Visualizer</a>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default HomePage;
