import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Container, Box, Typography, TextField, Select, MenuItem, FormControl, InputLabel, Slider, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import { OrbitControls, RoundedBox } from '@react-three/drei';
import { Helmet } from 'react-helmet-async';
import * as THREE from 'three';

const convertUnits = (value, fromUnit, toUnit) => {
  if (fromUnit === toUnit) return value;
  return fromUnit === 'inches' ? value * 2.54 : value / 2.54;
};

const roundToNearestQuarter = (value) => {
  return Math.round(value * 4) / 4;
};

const roundVolume = (value) => {
  return Math.round(value * 2) / 2;
};

const roundPercentage = (value) => {
  return Math.round(value);
};

const roundToHundredth = (value) => {
  return Math.round(value * 100) / 100;
};

const formatValue = (value) => {
  return value.toFixed(2);
};

const calculateVolume = (length, width, height, unit) => {
  let volume = length * width * height; // volume in cubic inches or cubic centimeters
  return volume;
};

const convertVolumeToUnit = (volume, fromUnit, toUnit) => {
  const conversions = {
    'cubicInches': {
      'quarts': volume / 57.75,
      'liters': volume * 0.0163871,
      'pints': volume / 28.875,
      'cups': volume / 14.4375,
      'fluidOunces': volume * 0.554113,
    },
    'cubicCentimeters': {
      'quarts': volume / 946.353,
      'liters': volume / 1000,
      'pints': volume / 473.176,
      'cups': volume / 236.588,
      'fluidOunces': volume / 29.5735,
    },
  };
  return fromUnit === 'inches' ? conversions['cubicInches'][toUnit] : conversions['cubicCentimeters'][toUnit];
};

const presets = [
  { label: 'Custom', value: 'custom' },
  { label: 'Sterilite 6 Qt', value: { length: 11.5, width: 6.5, height: 4.5, modelVolume: 6 } },
  { label: 'Sterilite 15 Qt', value: { length: 13.5, width: 9.38, height: 6.5, modelVolume: 15 } },
  { label: 'Sterilite 32 Qt', value: { length: 20.25, width: 13.75, height: 6.5, modelVolume: 32 } },
  { label: 'Sterilite 56 Qt', value: { length: 30.75, width: 16.25, height: 6.5, modelVolume: 56 } },
  { label: 'Sterilite 64 Qt', value: { length: 20.15, width: 13.75, height: 13.5, modelVolume: 64 } },
  { label: 'Sterilite 106 Qt', value: { length: 30, width: 16.25, height: 12.5, modelVolume: 106 } },
];

const initialIngredients = [
  { label: 'Coco Coir', value: 'cocoCoir' },
  { label: 'Vermiculite', value: 'vermiculite' },
  { label: 'Coffee Grounds', value: 'coffeeGrounds' },
  { label: 'Gypsum', value: 'gypsum' },
  { label: 'Hay or Straw', value: 'hayOrStraw' },
  { label: 'Sawdust', value: 'sawdust' },
  { label: 'Horse Manure', value: 'horseManure' },
  { label: 'Cow Manure', value: 'cowManure' },
  { label: 'Chicken Manure', value: 'chickenManure' },
  { label: 'Worm Castings', value: 'wormCastings' },
  { label: 'Other', value: 'other' },
];

const SteriliteBin = React.forwardRef(({ length, height, width, unit }, ref) => {
  const lengthInCm = convertUnits(length, unit, 'cm');
  const heightInCm = convertUnits(height, unit, 'cm');
  const widthInCm = convertUnits(width, unit, 'cm');

  return (
    <RoundedBox ref={ref} args={[lengthInCm, heightInCm, widthInCm]} radius={0.5} smoothness={4}>
      <meshStandardMaterial color="lightgray" transparent opacity={0.5} />
    </RoundedBox>
  );
});

const Substrate = ({ length, width, depth, unit, containerHeight }) => {
  const margin = 0.25; // Small margin to ensure substrate appears inside the container
  const lengthInCm = convertUnits(length - margin, unit, 'cm');
  const depthInCm = convertUnits(depth - margin, unit, 'cm');
  const widthInCm = convertUnits(width - margin, unit, 'cm');
  const containerHeightInCm = convertUnits(containerHeight, unit, 'cm');

  return (
    <RoundedBox
      args={[lengthInCm, depthInCm, widthInCm]}
      radius={0.5}
      smoothness={4}
      position={[0, (depthInCm - containerHeightInCm) / 2 + convertUnits(margin, unit, 'cm') / 2, 0]}
    >
      <meshStandardMaterial color="#8B4513" />
    </RoundedBox>
  );
};

const CameraController = ({ targetRef, length, height, width, unit }) => {
  const { camera, invalidate, controls } = useThree();
  const controlsRef = useRef();

  useEffect(() => {
    if (targetRef.current && controlsRef.current) {
      const box = new THREE.Box3().setFromObject(targetRef.current);
      const boxSize = box.getSize(new THREE.Vector3());
      const boxCenter = box.getCenter(new THREE.Vector3());

      const halfSizeToFitOnScreen = Math.max(boxSize.x, boxSize.y, boxSize.z) * 0.5;
      const halfFov = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFov);

      const direction = new THREE.Vector3(1, 1, 1).normalize(); // isometric view direction
      camera.position.copy(boxCenter.clone().add(direction.multiplyScalar(distance)));

      controlsRef.current.target.copy(boxCenter);
      controlsRef.current.update();

      invalidate();
    }
  }, [targetRef, camera, length, height, width, unit, invalidate]);

  return <OrbitControls ref={controlsRef} />;
};

const SubstrateVisualizer = () => {
  const defaultPreset = presets[1].value; // Default to 'Sterilite 6 Qt'

  const [unit, setUnit] = useState('inches');
  const [volumeUnit, setVolumeUnit] = useState('quarts');
  const [length, setLength] = useState(defaultPreset.length); // Default length in inches
  const [height, setHeight] = useState(defaultPreset.height); // Default height in inches
  const [width, setWidth] = useState(defaultPreset.width); // Default width in inches
  const [depth, setDepth] = useState(0); // Substrate depth
  const [preset, setPreset] = useState(presets[1]); // Default preset
  const [ratio, setRatio] = useState(2); // Default grain spawn to substrate ratio (1:2)
  const [ingredientsEnabled, setIngredientsEnabled] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientPercentages, setIngredientPercentages] = useState(
    initialIngredients.reduce((acc, ingredient) => {
      acc[ingredient.value] = 0;
      return acc;
    }, {})
  );
  const [customIngredients, setCustomIngredients] = useState([]);
  const [error, setError] = useState(false);

  const boxRef = useRef();

  const minDim = unit === 'cm' ? 2.54 : 1; // 1 inch or 2.54 cm

  useEffect(() => {
    if (depth > height) {
      setDepth(height);
    }
  }, [height, depth]);

  useEffect(() => {
    const totalPercentage = selectedIngredients.reduce((acc, ingredient) => acc + ingredientPercentages[ingredient], 0);
    setError(totalPercentage > 100);
  }, [selectedIngredients, ingredientPercentages]);

  useEffect(() => {
    const savedSettings = localStorage.getItem('substrateVisualizerSettings');
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      setUnit(settings.unit);
      setVolumeUnit(settings.volumeUnit);
      setLength(settings.length);
      setHeight(settings.height);
      setWidth(settings.width);
      setDepth(settings.depth);
      setPreset(settings.preset);
      setRatio(settings.ratio);
      setIngredientsEnabled(settings.ingredientsEnabled);
      setSelectedIngredients(settings.selectedIngredients);
      setIngredientPercentages(settings.ingredientPercentages);
      setCustomIngredients(settings.customIngredients || []);
    }
  }, []);

  useEffect(() => {
    const settings = {
      unit,
      volumeUnit,
      length,
      height,
      width,
      depth,
      preset,
      ratio,
      ingredientsEnabled,
      selectedIngredients,
      ingredientPercentages,
      customIngredients,
    };
    localStorage.setItem('substrateVisualizerSettings', JSON.stringify(settings));
  }, [unit, volumeUnit, length, height, width, depth, preset, ratio, ingredientsEnabled, selectedIngredients, ingredientPercentages, customIngredients]);

  const handleUnitChange = (newUnit) => {
    if (newUnit !== unit) {
      setLength(roundToNearestQuarter(convertUnits(length, unit, newUnit)));
      setHeight(roundToNearestQuarter(convertUnits(height, unit, newUnit)));
      setWidth(roundToNearestQuarter(convertUnits(width, unit, newUnit)));
      setDepth(roundToNearestQuarter(convertUnits(depth, unit, newUnit)));
      setUnit(newUnit);
    }
  };

  const handleVolumeUnitChange = (newUnit) => {
    setVolumeUnit(newUnit);
  };

  const handleDimensionChange = (setFunction) => (e) => {
    const roundedValue = roundToNearestQuarter(Math.max(minDim, e.target.value));
    setFunction(roundedValue);
  };

  const handlePresetChange = (event, newPreset) => {
    setPreset(newPreset);
    if (newPreset && newPreset.value !== 'custom') {
      setLength(newPreset.value.length);
      setHeight(newPreset.value.height);
      setWidth(newPreset.value.width);
    }
  };

  const handleRatioChange = (e, newValue) => {
    setRatio(newValue);
  };

  const handleIngredientChange = (ingredient) => (e, newValue) => {
    const newPercentage = Math.max(0, Math.min(100, newValue));
    setIngredientPercentages((prev) => ({
      ...prev,
      [ingredient]: newPercentage,
    }));
  };

  const handleIngredientsEnabledChange = (e) => {
    setIngredientsEnabled(e.target.checked);
  };

  const handleSelectedIngredientsChange = (event, newValue) => {
    const newIngredients = newValue.map((item) => {
      if (typeof item === 'string') {
        return { label: item, value: item };
      }
      return item;
    });
    setSelectedIngredients(newIngredients.map((item) => item.value));
  };

  const handleAddCustomIngredient = (event, newValue) => {
    if (newValue && !initialIngredients.some(ingredient => ingredient.label === newValue) && !customIngredients.some(ingredient => ingredient.label === newValue)) {
      const newIngredient = { label: newValue, value: newValue };
      setCustomIngredients([...customIngredients, newIngredient]);
      setSelectedIngredients([...selectedIngredients, newIngredient.value]);
      setIngredientPercentages((prev) => ({
        ...prev,
        [newIngredient.value]: 0,
      }));
    }
  };

  const containerVolumeCm3 = calculateVolume(length, width, height, unit);
  const substrateVolumeCm3 = calculateVolume(length, width, depth, unit);
  const grainSpawnVolumeCm3 = roundVolume(substrateVolumeCm3 / (1 + ratio));
  const adjustedSubstrateVolumeCm3 = roundVolume(substrateVolumeCm3 - grainSpawnVolumeCm3);

  const roundedSubstrateVolume = convertVolumeToUnit(adjustedSubstrateVolumeCm3, unit, volumeUnit);
  const roundedGrainSpawnVolume = convertVolumeToUnit(grainSpawnVolumeCm3, unit, volumeUnit);
  const substrateDepthPercentage = roundPercentage((depth / height) * 100);

  const ingredientVolumes = selectedIngredients.reduce((acc, ingredient) => {
    const percentage = ingredientPercentages[ingredient] / 100;
    acc[ingredient] = roundToHundredth(roundedSubstrateVolume * percentage);
    return acc;
  }, {});

  const containerVolume = preset && preset.value !== 'custom'
    ? (volumeUnit === 'quarts' ? preset.value.modelVolume : convertVolumeToUnit(preset.value.modelVolume * 946.353, 'cubicCentimeters', volumeUnit))
    : convertVolumeToUnit(containerVolumeCm3, unit, volumeUnit);

  return (
    <div className="app-background">
      <Helmet>
        <title>Substrate Calculator</title>
        <meta property="og:title" content="Substrate Calculator" />
        <meta property="og:description" content="Calculate substrate parameters." />
        <meta property="og:image" content="https://sporelore.co/static/images/preview_sub.png" />
        <meta property="og:url" content="https://sporelore.co/substrate-visualizer" />
        <meta property="og:site_name" content="SporeLore" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Substrate Calculator" />
        <meta name="twitter:description" content="Calculate substrate parameters." />
        <meta name="twitter:image" content="https://sporelore.co/static/images/preview_sub.png" />
      </Helmet>
      <div className="app-container">
        <Container maxWidth="xl">
          <Box mt={4}>
            <Typography variant="h4" gutterBottom>
              Bulk Substrate Visualizer
            </Typography>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                options={presets}
                getOptionLabel={(option) => option.label}
                value={preset}
                onChange={handlePresetChange}
                renderInput={(params) => <TextField {...params} label="Sterilite Box Preset" variant="outlined" />}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Unit</InputLabel>
              <Select value={unit} onChange={(e) => handleUnitChange(e.target.value)}>
                <MenuItem value="inches">Inches</MenuItem>
                <MenuItem value="cm">Centimeters</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Volume Unit</InputLabel>
              <Select value={volumeUnit} onChange={(e) => handleVolumeUnitChange(e.target.value)}>
                <MenuItem value="quarts">Quarts</MenuItem>
                <MenuItem value="liters">Liters</MenuItem>
                <MenuItem value="pints">Pints</MenuItem>
                <MenuItem value="cups">Cups</MenuItem>
                <MenuItem value="fluidOunces">Fluid Ounces</MenuItem>
              </Select>
            </FormControl>
            {preset && preset.value === 'custom' && (
              <>
                <TextField
                  label="Length"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={formatValue(length)}
                  onChange={handleDimensionChange(setLength)}
                  inputProps={{ min: minDim, step: 0.25 }}
                />
                <TextField
                  label="Height"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={formatValue(height)}
                  onChange={handleDimensionChange(setHeight)}
                  inputProps={{ min: minDim, step: 0.25 }}
                />
                <TextField
                  label="Width"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={formatValue(width)}
                  onChange={handleDimensionChange(setWidth)}
                  inputProps={{ min: minDim, step: 0.25 }}
                />
              </>
            )}
            {preset && preset.value !== 'custom' && (
              <Typography variant="body1" gutterBottom>
                Roughly {formatValue(length)} x {formatValue(width)} x {formatValue(height)} {unit}
              </Typography>
            )}
            <Typography variant="h6" gutterBottom>
              Substrate Depth: {formatValue(depth)} {unit} ({substrateDepthPercentage}%)
            </Typography>
            <Slider
              value={depth}
              onChange={(e, newValue) => setDepth(roundToNearestQuarter(newValue))}
              min={0}
              max={height}
              step={0.25}
              valueLabelDisplay="auto"
              aria-labelledby="substrate-depth-slider"
              valueLabelFormat={(value) => `${formatValue(value)} ${unit} (${roundPercentage((value / height) * 100)}%)`}
            />
            <Typography variant="h6" gutterBottom>
              Grain Spawn to Substrate Ratio
            </Typography>
            <Slider
              value={ratio}
              onChange={handleRatioChange}
              min={1}
              max={6}
              step={0.5}
              marks={[
                { value: 1, label: '1:1' },
                { value: 2, label: '1:2' },
                { value: 3, label: '1:3' },
                { value: 4, label: '1:4' },
                { value: 5, label: '1:5' },
                { value: 6, label: '1:6' },
              ]}
              valueLabelDisplay="auto"
              aria-labelledby="ratio-slider"
              valueLabelFormat={(value) => `1:${value}`}
            />
            <FormControlLabel
              control={<Checkbox checked={ingredientsEnabled} onChange={handleIngredientsEnabledChange} />}
              label="Enable Ingredient Composition"
            />
            {ingredientsEnabled && (
              <>
                {error && (
                  <Typography variant="body2" color="error" gutterBottom>
                    Total percentage exceeds 100%. Please adjust the ingredient percentages.
                  </Typography>
                )}
                <Box
                  mt={2}
                  mb={2}
                  p={2}
                  border={1}
                  borderColor={error ? 'red' : 'transparent'}
                  borderRadius={4}
                >
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[...initialIngredients, ...customIngredients]}
                      getOptionLabel={(option) => option.label || option}
                      value={selectedIngredients.map(value => [...initialIngredients, ...customIngredients].find(ingredient => ingredient.value === value) || { label: value, value })}
                      onChange={handleSelectedIngredientsChange}
                      onBlur={(event) => handleAddCustomIngredient(event, event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.target.value) {
                          handleAddCustomIngredient(null, event.target.value);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Ingredients" variant="outlined" />}
                    />
                  </FormControl>
                  {selectedIngredients.map((ingredient) => (
                    <Box key={ingredient} mt={2}>
                      <Typography variant="body2" gutterBottom>
                        {initialIngredients.find((ing) => ing.value === ingredient)?.label || ingredient}
                        <span style={{ float: 'right' }}>{ingredientPercentages[ingredient]}%</span>
                      </Typography>
                      <Slider
                        value={ingredientPercentages[ingredient]}
                        onChange={handleIngredientChange(ingredient)}
                        min={0}
                        max={100}
                        step={5}
                        valueLabelDisplay="auto"
                        aria-labelledby="ingredient-percentage-slider"
                        valueLabelFormat={(value) => `${value}%`}
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
            <Box mt={2} mb={2}>
              <Typography variant="h6">
                Container Volume: {formatValue(containerVolume)} {volumeUnit}
              </Typography>
              <Typography variant="h6">
                Grain Spawn Volume: {formatValue(roundedGrainSpawnVolume)} {volumeUnit}
              </Typography>
              <Typography variant="h6">
                Substrate Volume: {formatValue(roundedSubstrateVolume)} {volumeUnit}
              </Typography>
              {ingredientsEnabled && selectedIngredients.map((ingredient) => (
                <Typography key={ingredient} variant="body1" style={{ paddingLeft: '30px' }}>
                  {initialIngredients.find((ing) => ing.value === ingredient)?.label || ingredient} Volume: {formatValue(ingredientVolumes[ingredient])} {volumeUnit}
                </Typography>
              ))}
            </Box>
            <Box mt={4} height="700px">
              <Canvas camera={{ fov: 35 }}>
                <ambientLight intensity={0.5} />
                <directionalLight position={[5, 5, 5]} />
                <CameraController targetRef={boxRef} length={length} height={height} width={width} unit={unit} />
                {length > 0 && height > 0 && width > 0 && (
                  <>
                    <SteriliteBin ref={boxRef} length={length} height={height} width={width} unit={unit} />
                    {depth > 0 && (
                      <Substrate length={length} width={width} depth={depth} unit={unit} containerHeight={height} />
                    )}
                  </>
                )}
              </Canvas>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default SubstrateVisualizer;
