import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Container, Box, Typography, TextField, Select, MenuItem, FormControl, InputLabel, Slider, Checkbox, FormControlLabel, Link, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Helmet } from 'react-helmet-async';
import 'react-tooltip/dist/react-tooltip.css';
import '../App.css';

const getExperienceLevelRange = (level) => {
  switch (level) {
    case 1:
      return [3, 6, "Mild effects, some visual enhancement, elevated mood.", "This level produces mild effects, with some visual enhancement like brighter or more vibrant colors and sometimes an elevated mood. Some short term memory anomalies. Left/right brain communication changes causing music to sound 'wider'."];
    case 2:
      return [6, 12, "Bright colors, visuals, enhanced creativity.", "Bright colors, and visuals (ie. things start to move and breathe) some 2-dimensional patterns become apparent upon shutting eyes. Confused or reminiscent thoughts. The natural brain filter (salience network) begins to deactivate, thoughts may be distracted, creativity may feel enhanced, fixation on objects within your view or parts of your body can happen (for example staring at your hand or a flower or tree).  The salience network or midcingulo-insular network or ventral attention network, is a group of regions in your brain that work together to filter out unimportant information and decide what deserves your attention."];
    case 3:
      return [12, 18, "Obvious visuals, mild hallucinations, time distortions.", "Very obvious visuals, everything looking curved and/or warped patterns and kaleidoscopes seen on walls, faces, etc. Some mild hallucinations such as rivers flowing in wood grained or 'mother of pearl' surfaces. Closed eye hallucinations become 3 dimensional. Open eyed visuals everywhere with no effort. There could be some confusing of the senses (ie. seeing sounds as colors etc.) Time distortions and `moments of eternity`. Movement at times becomes extremely difficult (too much effort required)."];
    case 4:
      return [18, 30, "Strong hallucinations, loss of reality, out of body experiences.", "Strong hallucinations, objects morphing into other objects. The brain’s default mode network becomes less active or inactive resulting in loss of ego or splitting of the ego. Things may start talking to you, or you find that you are feeling contradictory things simultaneously. Some loss of reality. Time becomes meaningless. Out of body experiences and perceived esp type phenomena are possible. Different parts of the brain become “hyper-connected” resulting in full blending of the senses (synesthesia). You may experience colors when listening to music, see shapes when smelling certain scents, or perceive tastes when looking at words."];
    case 5:
      return [30, 40, "Total loss of visual connection with reality, total loss of ego.", "Total loss of visual connection with reality. The senses cease to function in the normal way. Total loss of ego. Merging with space, other objects or the universe. The loss of reality becomes so severe that it defies explanation. The earlier levels are relatively easy to explain in terms of measurable changes in perception and thought patterns. This level is different in that the actual universe within which things are normally perceived, ceases to exist. You may experience momentary periods of absolute peace and calm while the ego is suppressed and your brain is unencumbered by its usual worries or passions."];
    default:
      throw new Error("Invalid level. Please select a valid level.");
  }
};

const presets = [
  { label: 'Custom', value: 'custom' },
  { label: 'Pan Cyan modern species AVERAGE', value: 2.27 },
  { label: 'Pan Cyan TTBVI', value: 2.95 },
  { label: 'Pan Cyan Australia', value: 1.72 },
  { label: 'Pan Cyan Estero', value: 1.83 },
  { label: 'Pan Cyan Jamaica', value: 1.38 },
  { label: 'Natalensis modern species AVERAGE', value: 0.63 },
  { label: 'Natalensis GordoTEK', value: 1.05 },
  { label: 'Natalensis Sporeworks', value: 0.76 },
  { label: 'Natalensis Cubensis Cross Average', value: 1.31 },
  { label: 'Subtropicalis modern species AVERAGE', value: 1.42 },
  { label: 'Subtropicalis slow genetics', value: 2.37 },
  { label: 'Cubensis modern species AVERAGE', value: 0.85 },
  { label: 'Cubensis Steel Magnolia', value: 1.23 },
  { label: 'Cubensis A-', value: 0.55 },
  { label: 'Cubensis Albino A+', value: 0.5 },
  { label: 'Cubensis Albino Berma', value: 0.87 },
  { label: 'Cubensis Albino Chocolate Krinkle', value: 0.81 },
  { label: 'Cubensis Albino Emerald Gates', value: 1.68 },
  { label: 'Cubensis Albino JMF', value: 0.61 },
  { label: 'Cubensis Albino Malabar', value: 1.16 },
  { label: 'Cubensis Albino Penis Envy (APE)', value: 1.31 },
  { label: 'Cubensis Albino Riptide', value: 1.74 },
  { label: 'Cubensis Albino TAT x Melmac', value: 0.83 },
  { label: 'Cubensis Albino Texas', value: 0.92 },
  { label: 'Cubensis Albino Tidalwave', value: 0.74 },
  { label: 'Cubensis Albino Yak', value: 0.72 },
  { label: 'Cubensis Albino Yellow Umbo', value: 1.43 },
  { label: 'Cubensis ALF', value: 0.89 },
  { label: 'Cubensis AMVP', value: 1.15 },
  { label: 'Cubensis APE 300', value: 1.02 },
  { label: 'Cubensis APE OG', value: 1.7 },
  { label: 'Cubensis APE V10', value: 0.89 },
  { label: 'Cubensis Avery\'s Albino', value: 0.87 },
  { label: 'Cubensis B.A.P.E', value: 0.88 },
  { label: 'Cubensis B+', value: 0.5 },
  { label: 'Cubensis Black APE No. 1', value: 1.91 },
  { label: 'Cubensis Black Pearl', value: 0.7 },
  { label: 'Cubensis Bleach', value: 1.3 },
  { label: 'Cubensis Blu Gartha', value: 0.77 },
  { label: 'Cubensis Blue Brain Shataki', value: 0.61 },
  { label: 'Cubensis Blue Magnolia Squat', value: 0.54 },
  { label: 'Cubensis Blue Meanie', value: 0.62 },
  { label: 'Cubensis Blue Ridge Hillbilly', value: 0.78 },
  { label: 'Cubensis Blue Rust Magnolia x Ghost Bottle Cap', value: 0.72 },
  { label: 'Cubensis Blue Yonder', value: 0.87 },
  { label: 'Cubensis Bluey Vuitton', value: 0.88 },
  { label: 'Cubensis Brazil', value: 0.53 },
  { label: 'Cubensis Brown Albino', value: 0.68 },
  { label: 'Cubensis Bull Run', value: 0.61 },
  { label: 'Cubensis Cambodian', value: 0.39 },
  { label: 'Cubensis Caramel Popcorn Enigma', value: 0.92 },
  { label: 'Cubensis Casper', value: 1.7 },
  { label: 'Cubensis Cherokee', value: 0.58 },
  { label: 'Cubensis Chocolate Krinkle Brains', value: 1.01 },
  { label: 'Cubensis Clock Work Orange', value: 0.89 },
  { label: 'Cubensis Cluster Bomb', value: 0.71 },
  { label: 'Cubensis Colombian Rust', value: 0.72 },
  { label: 'Cubensis Compass', value: 0.71 },
  { label: 'Cubensis Cuba', value: 0.93 },
  { label: 'Cubensis Cupids Gates', value: 1.17 },
  { label: 'Cubensis Dark Chocolate KSAT', value: 0.47 },
  { label: 'Cubensis DC Mak 95', value: 0.68 },
  { label: 'Cubensis Deep End', value: 0.96 },
  { label: 'Cubensis Emerald Gates', value: 0.97 },
  { label: 'Cubensis Enigma', value: 0.79 },
  { label: 'Cubensis Enigma FD', value: 1.25 },
  { label: 'Cubensis Gemini', value: 1.39 },
  { label: 'Cubensis Ghost', value: 1.2 },
  { label: 'Cubensis Golden Teacher', value: 0.63 },
  { label: 'Cubensis Green Royalz', value: 1.11 },
  { label: 'Cubensis Halo Gates', value: 0.79 },
  { label: 'Cubensis Haole', value: 0.97 },
  { label: 'Cubensis Hillbilly', value: 0.91 },
  { label: 'Cubensis Hillbilly Pumpkin', value: 0.8 },
  { label: 'Cubensis Humble APE', value: 1.07 },
  { label: 'Cubensis Jack Frost', value: 1.04 },
  { label: 'Cubensis Jedi Mind Fuck', value: 0.5 },
  { label: 'Cubensis Juicy', value: 0.75 },
  { label: 'Cubensis Lizard King', value: 0.65 },
  { label: 'Cubensis Lucid Clouds', value: 1.2 },
  { label: 'Cubensis Lucid Gates', value: 1.26 },
  { label: 'Cubensis Mac Galactic', value: 0.91 },
  { label: 'Cubensis Makilla Gorilla', value: 0.81 },
  { label: 'Cubensis Maria Sabina Blue', value: 1.24 },
  { label: 'Cubensis Matapanas', value: 0.72 },
  { label: 'Cubensis Mazatapec', value: 0.58 },
  { label: 'Cubensis McKennaii', value: 0.95 },
  { label: 'Cubensis Melmac', value: 0.72 },
  { label: 'Cubensis Melmak SQUAT', value: 0.99 },
  { label: 'Cubensis MFH PE', value: 2.06 },
  { label: 'Cubensis Micro Ape', value: 1.16 },
  { label: 'Cubensis Mouse Face', value: 1.4 },
  { label: 'Cubensis Nut Cracker', value: 1.02 },
  { label: 'Cubensis Old Dirty PE', value: 0.78 },
  { label: 'Cubensis Omega Mutant', value: 1.05 },
  { label: 'Cubensis PE (Penis Envy)', value: 0.92 },
  { label: 'Cubensis Phobos', value: 0.42 },
  { label: 'Cubensis Pink Buffalo', value: 0.95 },
  { label: 'Cubensis Riptide', value: 1.13 },
  { label: 'Cubensis Shadow Fax', value: 0.85 },
  { label: 'Cubensis Shakti Blossom', value: 1.98 },
  { label: 'Cubensis Shataki', value: 0.97 },
  { label: 'Cubensis Silverback', value: 0.96 },
  { label: 'Cubensis SMAC', value: 1.28 },
  { label: 'Cubensis Smaug', value: 1.01 },
  { label: 'Cubensis Smurf Berry', value: 1.63 },
  { label: 'Cubensis Snape', value: 1.85 },
  { label: 'Cubensis Space King', value: 0.72 },
  { label: 'Cubensis Stargazer', value: 0.82 },
  { label: 'Cubensis Starry Night APE', value: 1.5 },
  { label: 'Cubensis Steel Mag', value: 0.82 },
  { label: 'Cubensis Storm Trooper', value: 0.98 },
  { label: 'Cubensis TAT', value: 0.87 },
  { label: 'Cubensis Tidal Wave', value: 0.66 },
  { label: 'Cubensis Tidalwave/APE', value: 0.85 },
  { label: 'Cubensis Toque', value: 1.3 },
  { label: 'Cubensis Toque F8 (Nerds)', value: 2.77 },
  { label: 'Cubensis Treasure Coast', value: 0.68 },
  { label: 'Cubensis Trinity', value: 0.93 },
  { label: 'Cubensis True Albino Teacher', value: 1.57 },
  { label: 'Cubensis UFO', value: 0.93 },
  { label: 'Cubensis White Rabbit', value: 0.5 },
  { label: 'Cubensis YAK', value: 0.76 },
  { label: 'Cubensis Yellow Umbo', value: 0.64 },
  { label: 'Cubensis Yeti', value: 1.18 },
  { label: 'Cubensis Z', value: 0.82 },
  { label: 'Cubensis Z^3', value: 0.72 },
  { label: 'Cubensis Zilla', value: 0.7 }
];

const videoConfigs = {
  1: { src: '/videos/video1.mp4' },
  2: { src: '/videos/video2.mp4' },
  3: { src: '/videos/video3.mp4' },
  4: { src: '/videos/video4.mp4' },
  5: { src: '/videos/video5.mp4' }
};

const CalculatorPage = () => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      preset: 'custom',
      customPotency: '',
      monthsInStorage: '',
      storageMethod: 1.67,
      experienceLevel: 1,
      grossWeightPerCapsule: '',
      showMath: false,
      disableVideo: false, // Default to false
    },
  });

  const watchAllFields = watch();
  const videoRef = useRef(null);
  const [sliderInteracted, setSliderInteracted] = useState(false);
  const [disclaimerExpanded, setDisclaimerExpanded] = useState(false);

  const [doseResult, setDoseResult] = useState('N/A');
  const [capsuleResult, setCapsuleResult] = useState('N/A');
  const [calculationDetails, setCalculationDetails] = useState('Calculation details: N/A');

  const calculateDose = useCallback(() => {
    const { preset, customPotency, monthsInStorage, storageMethod, experienceLevel, grossWeightPerCapsule } = watchAllFields;

    const potency = preset === 'custom' ? customPotency : preset;

    if (!potency) {
      setDoseResult('N/A');
      setCapsuleResult('N/A');
      setCalculationDetails('Calculation details: N/A');
      return;
    }

    const [minPCBE, maxPCBE] = getExperienceLevelRange(experienceLevel);
    const adjustedMonthsInStorage = monthsInStorage || 0;
    const adjustedStorageMethod = monthsInStorage ? storageMethod : 0;

    const minDose = (minPCBE / (1 - (adjustedMonthsInStorage * adjustedStorageMethod) / 100) / (potency / 100));
    const maxDose = (maxPCBE / (1 - (adjustedMonthsInStorage * adjustedStorageMethod) / 100) / (potency / 100));

    const suggestedDose = Math.round((minDose + maxDose) / 2);

    const doseInGrams = suggestedDose / 1000;

    setDoseResult(`${suggestedDose} mg (or ${doseInGrams.toFixed(2)}g)`);

    let calculationText = `Desired PCBE range: ${minPCBE}-${maxPCBE} mg
Months in storage: ${adjustedMonthsInStorage}
Loss of potency per month: ${adjustedStorageMethod}%
Potency: ${potency}%
Min dose: ${minDose.toFixed(2)} mg
Max dose: ${maxDose.toFixed(2)} mg
Suggested dose (average): ${suggestedDose} mg`;

    if (grossWeightPerCapsule) {
      const numCapsules = Math.floor(suggestedDose / grossWeightPerCapsule);
      setCapsuleResult(numCapsules.toString());
      calculationText += `\nGross weight per capsule: ${grossWeightPerCapsule} mg
Number of capsules: ${numCapsules}`;
    } else {
      setCapsuleResult('N/A');
      calculationText += `\nGross weight per capsule: N/A
Number of capsules: N/A`;
    }

    setCalculationDetails(calculationText.trim());
  }, [watchAllFields]);

  useEffect(() => {
    calculateDose();
  }, [watchAllFields, calculateDose]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && sliderInteracted && !watchAllFields.disableVideo) {
      const { experienceLevel } = watchAllFields;
      const { src } = videoConfigs[experienceLevel];
      videoElement.pause();
      if (videoElement.src !== src) {
        videoElement.src = src;
        videoElement.load();
      }
      videoElement.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    } else if (videoElement) {
      videoElement.pause();
    }
  }, [watchAllFields.experienceLevel, sliderInteracted, watchAllFields.disableVideo]);

  const handleSliderChange = (_, value) => {
    setValue('experienceLevel', value);
    if (!sliderInteracted) {
      setSliderInteracted(true);
    }
  };

  const handlePresetChange = (event, newValue) => {
    if (newValue) {
      setValue('preset', newValue.value);
      if (newValue.value !== 'custom') {
        setValue('customPotency', '');
      }
    }
  };

  const handleDisableVideoChange = (event) => {
    setValue('disableVideo', event.target.checked);
  };

  const currentLevel = getExperienceLevelRange(watchAllFields.experienceLevel);

  return (
    <div className="app-background">
      <Helmet>
        <title>Dosing Calculator</title>
        <meta property="og:title" content="Dosing Calculator" />
        <meta property="og:description" content="Calculate the optimal dosage of dried mushrooms based on various factors." />
        <meta property="og:image" content="https://sporelore.co/static/images/preview.png" />
        <meta property="og:url" content="https://sporelore.co/dose-calculator" />
        <meta property="og:site_name" content="SporeLore" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dosing Calculator" />
        <meta name="twitter:description" content="Calculate the optimal dosage of dried mushrooms based on various factors." />
        <meta name="twitter:image" content="https://sporelore.co/static/images/preview.png" />
      </Helmet>
      <video ref={videoRef} className="video-background" muted loop playsInline webkit-playsinline></video>
      <div className="app-container">
        <Container maxWidth="xl">
          <Box mt={4}>
            <Typography variant="h4" gutterBottom>Dosing Calculator</Typography>

            <FormControl fullWidth margin="normal">
              <Autocomplete
                options={presets}
                getOptionLabel={(option) => `${option.label} (${option.value !== 'custom' ? option.value : ''}%)`}
                onChange={handlePresetChange}
                renderInput={(params) => <TextField {...params} label="Potency preset or custom value" variant="outlined" />}
              />
            </FormControl>

            {watchAllFields.preset !== 'custom' && (
              <Box mt={1} p={2} sx={{ backgroundColor: 'warning.light', borderRadius: '8px' }}>
                <Typography variant="body2">
                  Warning - potency values can vary significantly from the averages. For optimal accuracy, it's best to test your own samples.&nbsp;
                  <Link href="https://uswebshop.miraculix-lab.com/product/psilo-qtest/ref_us/14/?v=a284e24d5f46&campaign=dose-calc" target="_blank" rel="noopener noreferrer">
                    Learn more about testing your mushrooms at home with the Miraculix Psilocybin QTest.
                  </Link>
                </Typography>
              </Box>
            )}

            {watchAllFields.preset === 'custom' && (
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Enter custom PCBE %"
                  type="number"
                  inputProps={{ min: "0", step: "0.01" }}
                  {...control.register("customPotency", { valueAsNumber: true })}
                  InputProps={{
                    style: { backgroundColor: 'white' }
                  }}
                />
              </FormControl>
            )}

            <FormControl fullWidth margin="normal">
              <TextField
                label="Months in storage"
                type="number"
                inputProps={{ min: "0" }}
                {...control.register("monthsInStorage", { valueAsNumber: true })}
                InputProps={{
                  style: { backgroundColor: 'white' }
                }}
                helperText="Enter 0 or leave blank if not applicable. If tested, only count months since testing date"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="storageMethod-label">Storage method</InputLabel>
              <Controller
                name="storageMethod"
                control={control}
                render={({ field }) => (
                  <Select labelId="storageMethod-label" {...field}>
                    <MenuItem value={1.67}>Whole without Argon - 1.67% per month</MenuItem>
                    <MenuItem value={1.44}>Powdered without Argon - 1.44% per month</MenuItem>
                    <MenuItem value={1.57}>Whole with Argon - 1.57% per month</MenuItem>
                    <MenuItem value={1.16}>Powdered with Argon - 1.16% per month</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Capsule weight (mg)"
                type="number"
                inputProps={{ min: "0" }}
                {...control.register("grossWeightPerCapsule", { valueAsNumber: true })}
                InputProps={{
                  style: { backgroundColor: 'white' }
                }}
                helperText="Optional"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <Typography id="experienceLevel-label" gutterBottom>Desired experience</Typography>
              <Box display="flex" alignItems="center">
                <Controller
                  name="experienceLevel"
                  control={control}
                  render={({ field }) => (
                    <Slider
                      {...field}
                      aria-labelledby="experienceLevel-label"
                      step={1}
                      marks
                      min={1}
                      max={5}
                      valueLabelDisplay="auto"
                      onChange={handleSliderChange}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watchAllFields.disableVideo}
                      onChange={handleDisableVideoChange}
                    />
                  }
                  label="Disable video background"
                  sx={{ ml: '16px' }}
                />
              </Box>
              <Typography id="experienceLevel">Level {watchAllFields.experienceLevel}</Typography>
              <Typography id="experienceValue">
                {currentLevel[0]}-{currentLevel[1]} mg PCBE 
                <span data-tooltip-id="pcbe-tooltip" data-tooltip-content="PCBE = “psilocybin equivalent”. This is a measure of potency that incorporates both PCB and PCN, converting % psilocin to what would be an equivalent amount of psilocybin, incorporating the difference in molecular weight of the two alkaloids. Its formula is roughly PCB + (1.3915 * PCN).">
                  &nbsp;<Link href="#">[?]</Link>&nbsp;
                </span>
                <ReactTooltip id="pcbe-tooltip" effect="solid" />
                (this is NOT the suggested weight)
              </Typography>
              <br />
              <span data-tooltip-id="experience-tooltip" data-tooltip-content={currentLevel[3]}>
                <Typography id="experienceDescription">
                  {currentLevel[2]} (Hover for more info)
                </Typography>
              </span>
              <ReactTooltip id="experience-tooltip" effect="solid" />
            </FormControl>

            <FormControlLabel
              control={<Checkbox {...control.register("showMath")} />}
              label="Show calculation details"
            />
          </Box>
        <Box px={4} mt={4} className="results-container">
          <Typography variant="h5" id="doseResult" className="result">Mg of dried mushroom to take: {doseResult}</Typography>
          <Typography variant="h5" id="capsuleResult" className="result">Number of capsules to take: {capsuleResult}</Typography>
          {watchAllFields.showMath && <pre id="calculation" className="calculation-details">{calculationDetails}</pre>}
        </Box>
        <Box px={4} mt={4} className="attribution-container">
          <Typography variant="body1" align="left">
            <h2>Acknowledgments</h2>
            <p>Special thanks to <a href="https://www.patreon.com/GordoTEK">Gordo</a> for his invaluable work and community-building efforts. Learn more about the science behind mushroom storage and preservation on his <a href="https://www.patreon.com/posts/mushroom-storage-104822283">Patreon page</a>.</p>
            <p>Members of Gordo's community were also instrumental in providing feedback and suggestions for this calculator. Thank you to everyone who contributed to this project.</p>
          </Typography>
        </Box>
        <Box p={4} my={4} className="disclaimer-container">
          <Typography variant="body1" align="left">
            <h2>Please Read Before Use</h2>
            <Button onClick={() => setDisclaimerExpanded(!disclaimerExpanded)}>
              {disclaimerExpanded ? 'Hide' : 'Show'}
            </Button>
            {disclaimerExpanded && (
              <div>
                <p>The information provided by this dosing calculator is for educational and informational purposes only. This calculator is intended to assist users in estimating the dosage of dried mushrooms based on various factors, including potency, storage time, and desired experience level. However, the accuracy and reliability of these estimates cannot be guaranteed.</p>

                <h2>Important Points:</h2>
                <ul>
                  <li><strong>Sensitivity Testing:</strong> To help determine personal sensitivity, it is strongly recommended to start at the lower levels and work your way up from there as desired, one level at a time with at least 2 weeks between experiences.</li>
                  <li><strong>Maximum Dosage:</strong> Note that researchers have found no particular value in exceeding the highest recommended dosage range. Excessive doses can cause elevated fear, anxiety, panic, sweating, paranoia, nausea, dizziness, vomiting, diarrhea, and other adverse effects.</li>
                  <li><strong>No Medical Advice:</strong> This calculator does not provide medical advice, diagnosis, or treatment. It should not be used as a substitute for professional medical advice.</li>
                  <li><strong>Accuracy of Information:</strong> While we strive to provide accurate and up-to-date information, we cannot guarantee the accuracy, completeness, or timeliness of the information presented by this calculator. The dosing recommendations are based on general guidelines and may not be suitable for every individual.</li>
                  <li><strong>Use at Your Own Risk:</strong> The use of this calculator and the information it provides is at your own risk. We are not responsible for any adverse effects or consequences resulting from the use or misuse of the information provided.</li>
                  <li><strong>Legal Considerations:</strong> The possession, use, and distribution of psychedelic substances may be illegal in your jurisdiction. It is your responsibility to ensure that you comply with all applicable laws and regulations.</li>
                  <li><strong>Not for Minors:</strong> This calculator is intended for use by adults only. It is not suitable for use by minors or individuals under the legal age of consent in their jurisdiction.</li>
                </ul>

                <h2>Affiliate Links:</h2>
                <p>Some links on this site are affiliate links. If you choose to make a purchase through these links, I may earn a small commission at no additional cost to you. This helps support the site and ongoing efforts to provide accurate and useful information.</p>
              </div>
            )}
          </Typography>
        </Box>
        </Container>
      </div>
    </div>
  );
};

export default CalculatorPage;
