import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import HomePage from './components/HomePage';
import CalculatorPage from './components/CalculatorPage';
import SubstrateVisualizer from './components/SubstrateVisualizer';

const App = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dose-calculator" element={<CalculatorPage />} />
        <Route path="/substrate-visualizer" element={<SubstrateVisualizer />} />
      </Routes>
    </>
  );
};

export default App;
